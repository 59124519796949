import { ApiPromise, WsProvider } from '@polkadot/api'
import { web3FromAddress } from '@polkadot/extension-dapp'
import jsonrpc from '@polkadot/types/interfaces/jsonrpc'
import { User, UserProfile } from 'src/interface'
import { Community } from 'src/pages/communities'
import API from '../api'

async function api() {
	try {
		const isLocal = window.location.host.includes('localhost')
		let url = isLocal ?'wss://dev.kinera.network' : 'wss://node.kinera.network'
		const wsProvider = new WsProvider(url)
		const api = await ApiPromise.create({
			provider: wsProvider,
			rpc: jsonrpc,
		})
		return api
	} catch (error) {
		console.log(error)
	}
}

const getCommunities = async (type: string) => {
	try {
		let apiCommunity = await api()
		if (apiCommunity) {
			let maped: any = []
			let req = await apiCommunity.query.communitiesModule.communitiesByVoteResult(type)
			if (req) {
				let formated: any = req.toHuman()

				formated.forEach((value: any) => {
					return maped.push(value)
				})
			}
			return maped
		} else {
			return []
		}
	} catch (err) {
		console.log(err)
	}
}

const getCommunitiesByUser = async (user: string) => {
	try {
		let apiCommunity = await api()
		if (apiCommunity) {
			let maped: any = []
			let req = await apiCommunity.query.communitiesModule.userCommunities(user)
			if (req) {
				let formated: any = req.toHuman()
				formated.forEach((value: any) => {
					return maped.push(value)
				})
			}
			return maped
		} else {
			return []
		}
	} catch (err) {
		console.log(err)
	}
}

const getCommunitiesById = async (id: string) => {
	try {
		let apiCommunity = await api()
		if (apiCommunity) {
			let req = await apiCommunity.query.communitiesModule.communities(id)
			if (req) {
				let formated: any = req.toHuman()

				return formated
			}
		} else {
			return []
		}
	} catch (err) {
		console.log(err)
	}
}

const getCommunity = async (id: string) => {
	try {
		let apiCommunity = await api()
		if (apiCommunity) {
			let req = await apiCommunity.query.communitiesModule.communities(id)
			if (req) {
				let formated: any = req.toHuman()

				return formated
			}
		} else {
			return []
		}
	} catch (err) {
		console.log(err)
	}
}

const applyForCommunity = async (user: any, community: Community, handleGlobalMessage:(param:string) => void) => {
	try {
		const connect = await api()
		const injector = await web3FromAddress(user.address)
		const send = connect?.tx.communitiesModule.addMember(community.id, user.name)
		await send?.signAndSend(user.address, { signer: injector.signer }, (result: any) => {
			if (Object.keys(community.communityType)[0] === 'Public') {
				createDbRegisterUserCommunity(community.id, user.address, Object.keys(community.communityType)[0], user.name)
				handleGlobalMessage('Your transaction is being processed.')
			}
		})
	} catch (err) {
		if (err instanceof Error) {
			handleGlobalMessage(err.message)
		} else {
			handleGlobalMessage('An error occurred while sending the request to your wallet, log in again')
		}
	}
}

const applyForCommunityMonthly = async (user: any, community: Community) => {
	try {
		const connect = await api()
		const injector = await web3FromAddress(user.address)
		const send = connect?.tx.communitiesModule.approvalByPayment(community.id, user.name)
		await send?.signAndSend(user.address, { signer: injector.signer }, (result: any) => {
			if (result.status.isFinalized) {
				//sendPayment(Object.values(community.communityType)[0].monthlyFee, community.id, user)
			}
		})
	} catch (err) {
		console.log(err)
	}
}

const getCurrentAccBlock = async () => {
	let apiCommunity = await api()
	const get: any = await apiCommunity?.query.system.number()
	return get.toHuman()
}

const createCommunityProposal = async (
	user: any,
	title: string,
	description: string,
	reason: string,
	type: string,
	icon: string,
	monthlyFee: any,
	category: string,
	setState: (param: boolean) => void,
	handleConclude: () => void,
	handleGlobalMessage: (param: string) => void,
) => {
	try {
		const connect = await api()
		const injector = await web3FromAddress(user.address)

		// Adjust the extrinsic to include the monthlyFee
		const send = connect?.tx.communitiesModule.createCommunity(
			title,
			description,
			{ [type]: { monthlyFee } }, // Format based on type (Public or Private)
			icon,
			user.name,
			reason,
			category,
		)

		handleGlobalMessage('Your transaction is being processed.')
	
		await send?.signAndSend(user.address, { signer: injector.signer }, (result: any) => {
			if (result.status.isFinalized) {
				setState(false)
				
			} else {
				setState(true)
			}
		})
		handleConclude()
	} catch (err: any) {
		setState(true)
		// Tenta capturar a mensagem do erro
		if (err instanceof Error) {
			handleGlobalMessage(err.message)
			console.log(err.message)
		} else {
			handleGlobalMessage('An error occurred while sending the request to your wallet, log in again')
		}
	}
}

const submiteCommunityVote = async (
	user: any,
	id: string,
	type: boolean,
	setState: (param: boolean) => void,
	community: Community,
	handleGlobalMessage: (param: string) => void,
) => {
	try {
		const connect = await api()
		const injector = await web3FromAddress(user.address)
		const send = connect?.tx.communitiesModule.submitVote(id, type, user.name)
		await send?.signAndSend(user.address, { signer: injector.signer }, (result: any) => {
			if (result.status.isFinalized) {
				if (type === true) {
					let formated = parseInt(community.votesApprove) + 1
					community.votesApprove = formated.toString()
				} else {
					let formated = parseInt(community.votesReject) + 1
					community.votesReject = formated.toString()
				}
				setState(false)
			} else {
				setState(true)
			}
		})
	} catch (err) {
		handleGlobalMessage('An error occurred while sending the request to your wallet, log in again')
		console.log(err)
	}
}

const approveMember = async (
	user: any,
	id: string,
	address: string,
	name: string,
	community: Community,
	setState: (param: boolean) => void,
) => {
	try {
		const connect = await api()
		const injector = await web3FromAddress(user.address)
		const send = connect?.tx.communitiesModule.approveMember(id, address, name)
		await send?.signAndSend(user.address, { signer: injector.signer }, (result: any) => {
			if (result.status.isFinalized) {
				createDbRegisterUserCommunity(community.id, address, '', name)
				setState(false)
			} else {
				setState(true)
			}
		})
	} catch (err) {
		console.log(err)
	}
}

const createDbRegisterUserCommunity = async (
	community_id: string,
	user_address: string,
	type: string,
	name: string,
) => {
	let payload = {
		user_address: user_address,
		community_id: community_id,
		type: type,
		name: name,
	}
	try {
		await API.post(`/api/community/`, payload)
	} catch (e) {
		console.log(e)
	}
}

const verifyUserExistInCommunity = async (user: User, communityId: string) => {
	try {
		const connect = await api()
		const req = await connect?.query.communitiesModule.communityMembers(communityId)
		if (req) {
			let formated: any = req.toHuman()
			let verify = false

			if (formated && formated.length) {
				formated.forEach((val: { user: string }) => {
					if (val.user === user.address) {
						verify = true
					}
				})

				return verify
			} else {
				return false
			}
		} else {
			return false
		}
	} catch (err) {
		console.log(err)
		return false
	}
}

const verifyPendingRequest = async (user: User, communityId: string) => {
	try {
		const connect = await api()
		const req = await connect?.query.communitiesModule.pendingEntryRequests(communityId)
		if (req) {
			let formated: any = req.toHuman()
			let verify = false

			if (formated && formated.length) {
				formated.forEach((val: { user: string }) => {
					if (val.user === user.address) {
						verify = true
					}
				})

				return verify
			} else {
				return false
			}
		} else {
			return false
		}
	} catch (err) {
		console.log(err)
		return false
	}
}

const getAllMembersInCommunity = async (communityId: string) => {
	try {
		const connect = await api()
		const req = await connect?.query.communitiesModule.communityMembers(communityId)
		if (req) {
			let formated: any = req.toHuman()
			return formated
		} else {
			return []
		}
	} catch (err) {
		console.log(err)
	}
}

const verifyUserVoteCommunity = async (user: User, communityId: string) => {
	try {
		const connect = await api()
		const req = await connect?.query.communitiesModule.communityCreateVoters(communityId)
		if (req) {
			let formated: any = req.toHuman()

			return formated
		} else {
			return []
		}
	} catch (err) {
		console.log(err)
	}
}

const pendingEntryRequests = async (communityId: string) => {
	try {
		const connect = await api()
		const req = await connect?.query.communitiesModule.pendingEntryRequests(communityId)
		if (req) {
			let formated: any = req.toHuman()

			return formated
		} else {
			return []
		}
	} catch (err) {
		console.log(err)
	}
}

const sendPayment = async (amount: string, community_id: string, user_address: string) => {
	let payload = {
		amount: amount,
		community_id: community_id,
		user_address: user_address,
	}
	try {
		await API.post(`/api/payment/community`, payload)
	} catch (e) {
		console.log(e)
	}
}

const verifyExistCommunityName = async (title: string, user_address: string) => {
	let payload = {
		community_name: title,
		user_address: user_address,
	}
	try {
		let req = await API.post(`/api/communities/verify`, payload)
		return req.data
	} catch (e) {
		console.log(e)
	}
}

const createCommunityDocument = async (title: string, user_address: string, description: string, community_id: string, address?:string) => {
	let payload = {
		title: title,
		user_id: user_address,
		description: description,
		privacity: "",
		is_community: true,
		community_id: community_id
	}
	try {
		let req = await API.post(`/api/ipfs/collaborative`, payload)
		if(req && req.data) {
			let newPostPayload = {
				address: address,
				content: `New document created: <span className="text-underline underline">${req.data.title}<span>`,
				type:  'update',
				isFestival: false,
				categorie: 3,
				event_id: '',
				urls: '',
				community_id: community_id,
				is_community_post: true
			}
			await API.post(`/api/social/`, newPostPayload)

		}
		return req.data
	} catch (e) {
		return false

	}
}

const getCommunityDocuments = async (community_id: string) => {
	
	try {
		let req = await API.get(`/api/ipfs/collaborative/community/${community_id}`)
		return req.data
	} catch (e) {
		return false

	}
}

const createCommunityPrompt = async (payload: {community_id: string, name: string, content:string, files_ids:string}) => {
	
	try {
		let req = await API.post(`/api/communities/ipfs/prompts`, payload)
		return req.data
	} catch (e) {
		return false

	}
}


const updatePrompt = async (payload: {community_id: string, name: string, content:string, id:string, files_ids:string}) => {
	
	try {
		let req = await API.post(`/api/communities/ipfs/prompts/${payload.community_id}`, payload)
		return req.data
	} catch (e) {
		return false

	}
}

const getCommunitiesPrompts = async (community_id: string) => {
	
	try {
		let req = await API.get(`/api/communities/ipfs/prompts/${community_id}`)
		return req.data
	} catch (e) {
		return false

	}
}

export const getWorkSpaceFiles = async (files: string) => {
	try {
		let payload ={
			files: files
		}
		let req = await API.post(`/api/storage/ipfs/workspace`, payload)
		return req.data
	} catch (e) {
		return false

	}
}

const getCommunitiesRags = async (community_id: string) => {
	
	try {
		let req = await API.get(`/api/communities/ipfs/rags/${community_id}`)
		return req.data
	} catch (e) {
		return false

	}
}

const getCommunitiesFiles = async (community_id: string) => {
	
	try {
		let req = await API.get(`/api/communities/ipfs/files/${community_id}`)
		return req.data
	} catch (e) {
		return false

	}
}

const createCommunityCharacter = async (payload: {community_id: string, name: string, description:string, params:string, img_url: string, base_param: string}, address:string) => {
	
	try {
		let req = await API.post(`/api/communities/ipfs/characters`, payload)
		if(req && req.data) {
			let newPostPayload = {
				address: address,
				content: `New Character created: <span class="text-underline underline">${req.data.name}<span>`,
				type:  'update',
				isFestival: false,
				categorie: 3,
				event_id: '',
				urls: '',
				community_id: payload.community_id,
				is_community_post: true
			}
			await API.post(`/api/social/`, newPostPayload)

		}
		return req.data
	} catch (e) {
		return false

	}
}

const getAllCommunitiesCharacters = async (community_id: string) => {
	try {
		let req = await API.get(`/api/communities/ipfs/list/characters/${community_id}/`)
		return req.data
	} catch (e) {
		return false
	}
}

const updateCharacter = async (payload: {community_id: string, name: string, description:string, params:string, img_url: string, base_param: string, id:string}) => {
	
	try {
		let req = await API.post(`/api/communities/ipfs/characters/${payload.community_id}`, payload)
		return req.data
	} catch (e) {
		return false
	}
}

const removeUseChar = async (payload: {community_id: string,  id:string}) => {
	
	try {
		let req = await API.post(`/api/communities/ipfs/remove/characters/${payload.community_id}`, payload)
		return req.data
	} catch (e) {
		return false
	}
}

const deleteCommunity = async (
	user: User,
	communityId: string,

) => {
	try {
		const connect = await api()
		const injector = await web3FromAddress(user.address)
		const send = connect?.tx.communitiesModule.deleteCommunity(communityId)
		await send?.signAndSend(user.address, { signer: injector.signer }, (result: any) => {
			if (result.status.isFinalized) {
				
			}
		})
	} catch (err) {
		console.log(err)
	}
}


export const communityService = {
	deleteCommunity,
	removeUseChar,
	updateCharacter,
	createCommunityCharacter,
	getCommunitiesRags,
	getAllCommunitiesCharacters,
	getCommunitiesFiles,
	getCommunitiesPrompts,
	updatePrompt,
	getCommunityDocuments,
	applyForCommunityMonthly,
	getCommunities,
	applyForCommunity,
	verifyUserExistInCommunity,
	createCommunityProposal,
	getCommunity,
	getCurrentAccBlock,
	submiteCommunityVote,
	verifyUserVoteCommunity,
	getCommunitiesByUser,
	getCommunitiesById,
	getAllMembersInCommunity,
	verifyPendingRequest,
	pendingEntryRequests,
	approveMember,
	verifyExistCommunityName,
	createCommunityDocument,
	createCommunityPrompt
}
