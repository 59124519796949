import React from 'react'
import { TextStyleBodyDefaultMedium, TextStyleCaption } from 'src/components/_Dsc/typography'

export const models = [
	{ label: 'llama3.2', description: 'Meta Llama 3: The most capable openly available LLM to date', value: 'llama3.2:latest' },
	{ label: 'Arena Model', value: 'arena-model' },
	{ label: 'gemma2:9b', description: 'gemma2:9b', value: 'gemma2:9b' },
	{ label: 'Qwen2.5-14B', description: 'Qwen-2.5-14B is a powerful and efficient language model with 14 billion parameters.', value: 'qwen2.5:14b' },
]

export const Models: React.FC = () => {
	return (
		<div className="flex flex-col gap-2 py-4">
			{models.map((val: { label: string; value: string; description?:string }, key: number) => (
				<div className="flex border border-1 border-[#ededed] p-2 rounded-[4px] cursor-pointer hover:bg-gray-100 flex-col" key={key}>
					<TextStyleBodyDefaultMedium>{val.label}</TextStyleBodyDefaultMedium>
                    <TextStyleCaption>{val.description || ''}</TextStyleCaption>
				</div>
			))}
		</div>
	)
}
