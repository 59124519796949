import axios from 'axios'
import { Message } from './components/Chat'
//import Cookies from 'js-cookie'

export let path = [
	{ label: 'Home', link: '/' },
	{ label: 'Messenger', link: '/messenger' },
]


export const baseSocketUrl = 'https://p2p-chat.kinera.network'
const isLocal = window.location.host.includes('localhost')

// Cria uma instância de axios com a configuração baseURL
export const baseApiSocket = axios.create({
	baseURL: isLocal ? 'http://localhost:3001' : 'https://p2p-chat.kinera.network',
	headers: {
		'x-cors-api-key': process.env.REACT_APP_KEY_PROXY, // Adicione esta chave se necessário
	},
})

export const verifypermission = async(chatId:string) => {
	try {
		let req = await baseApiSocket.get(`/chats/${chatId}`)
		return req.data
	}catch(err) {
		console.log(err)
	}
}

export const sendMessage = async(message:Message) => {
	try {
		let req = await baseApiSocket.post(`/messages/`, message)
		return req.data
	}catch(err) {
		console.log(err)
	}
}