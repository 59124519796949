import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Button from 'src/components/_Dsc/Button'
import InputText from 'src/components/_Dsc/InputText'
import { DsyLabel, DsyRequired } from 'src/components/_Dsc/InputText/styles'
import Modal from 'src/components/_Dsc/Modal'
import {
	TextStyleBodyDefault,
	TextStyleBodyDefaultLarge,
	TextStyleBodyDefaultMedium,
	TextStyleCaption,
	TextStyleH2,
} from 'src/components/_Dsc/typography'
import { Loading } from 'src/components/loading'
import KineContext from 'src/context/KineContext'
import { communityService, getWorkSpaceFiles } from 'src/service/community/communityService'
import { CommunityFile } from './Rags'
import { Divider } from '@mui/material'
import axios from 'axios'
import { models } from './Models'

export interface WorkSpace {
	id: string
	community_id: number
	name: string
	content: string
	files_ids: string
	owner_id: string
	owner_name: string
	created_at: string
	updated_at: string
	model: string
}

export const WorkSpace: React.FC = () => {
	const { user, handleGlobalMessage, setOnQueue } = React.useContext(KineContext)
	const [showModal, setShowModal] = useState<boolean>(false)
	const [files, setFiles] = useState<CommunityFile[] | null>(null)
	const [inFetch, setInFetch] = useState<boolean>(true)
	const [inFetchFiles, setInFetchFiles] = useState<boolean>(true)
	const [isEdit, setIsEdit] = useState<boolean>(false)
	const [title, setTitle] = useState<string>('')
	const [content, setContent] = useState<string>('')
	const [inFetchWorkSpace, setInFetchWorkSpace] = useState<boolean>(false)
	const [prompts, setPrompts] = useState<WorkSpace[]>([])
	const [type, setType] = useState('')
	const [inputText, setInputText] = useState('')
	const [selectedFileIds, setSelectedFileIds] = useState<string>('')
	const [selectedWorkSpace, setSelectedWorkSpace] = useState({} as WorkSpace)
	const [openInteractModal, setOpenInteractModal] = useState<boolean>(false)
	const [loading, setLoading] = useState(false)
	const [responses, setResponses] = useState<string[]>([])
	const [filesSelected, setFilesSelected] = useState<string[]>([])
	const [workSpaceFiles, setWorkSpaceFiles] = useState<CommunityFile[]>([])
	const [currentPrompt, setCurrentPrompt] = useState<WorkSpace>({} as WorkSpace)
	const { id } = useParams()

	const getAllPrompts = async () => {
		if (id) {
			setInFetch(true)
			let req = await communityService.getCommunitiesPrompts(id)
			setPrompts(req)
			setInFetch(false)
		} else {
			setInFetch(false)
			return
		}
	}

	const getAllFilesInWorkSpace = async (files: string) => {
		if (id) {
			setInFetchWorkSpace(true)
			let req = await getWorkSpaceFiles(files)
			setWorkSpaceFiles(req)
			let arrayIds: string[] = []
			req.map((val: CommunityFile) => {
				arrayIds.push(val.gpt_file_id)
			})
			setFilesSelected(arrayIds)
			setInFetchWorkSpace(false)
		} else {
			setInFetchWorkSpace(false)
			return
		}
	}

	const onClose = () => {
		setShowModal(false)
	}

	const onConfirm = async () => {
		if (!id) {
			return
		}

		setOnQueue(true)

		if (isEdit) {
			let payload = {
				community_id: id,
				name: title,
				content: content,
				files_ids: selectedFileIds ? selectedFileIds : 'null',
				id: currentPrompt.id,
				model: type,
			}
			await communityService.updatePrompt(payload)
		} else {
			let payload = {
				community_id: id,
				name: title,
				content: content,
				files_ids: selectedFileIds ? selectedFileIds : 'null',
				model: type,
			}
			await communityService.createCommunityPrompt(payload)
		}
		setShowModal(false)
		handleGlobalMessage('Prompt created success')
		setIsEdit(false)
		getAllPrompts()
	}

	const handleModalChange = () => {
		setShowModal(true)
		getRags()
	}

	const handleInputChange = (param: string, origin: string) => {
		if (origin === 'title') {
			setTitle(param)
		} else if (origin === 'content') {
			setContent(param)
		}
	}

	const handleOpenEditModal = (prompt: WorkSpace) => {
		setCurrentPrompt(prompt)
		setIsEdit(true)
		setShowModal(true)
		setTitle(prompt.name)
		setSelectedFileIds(prompt.files_ids)
		setContent(prompt.content)
		setType(prompt.model)
	}

	const postChatCompletion = async () => {
		try {
			let filesSelecteds: any = []
			filesSelected.map((val: string) => {
				return filesSelecteds.push({ type: 'file', id: val })
			})
			setInputText('')
			const response = await axios.post(
				'https://open-gpt.kinera.network/api/chat/completions',
				{
					model: selectedWorkSpace.model ?? 'arena-model',
					messages: [
						{
							role: 'user',
							content: inputText,
						},
					],
					files: filesSelecteds,
				},
				{
					headers: {
						Authorization: `Bearer ${process.env.REACT_APP_GPT_KEY}`,
						'Content-Type': 'application/json',
					},
				},
			)

			setResponses(prev => [...prev, `Question: ${inputText}`, `Answer: ${response.data.choices[0].message.content}`])
		} catch (error) {
			console.error('Error:', error)
		} finally {
			setLoading(false)
		}
	}

	const getRags = async () => {
		if (id) {
			setInFetchFiles(true)
			let req = await communityService.getCommunitiesRags(id)
			if (req) {
				setFiles(req)
			}
			setInFetchFiles(false)
		}
	}

	const handleSend = async () => {
		setLoading(true)
		postChatCompletion()
	}

	const handleCheckboxChange = (fileId: string, isChecked: boolean) => {
		setSelectedFileIds(prev => {
			const idsArray = prev ? prev.split(',') : []
			if (isChecked) {
				// Adiciona o ID se marcado
				if (!idsArray.includes(fileId)) {
					idsArray.push(fileId)
				}
			} else {
				// Remove o ID se desmarcado
				const index = idsArray.indexOf(fileId)
				if (index > -1) {
					idsArray.splice(index, 1)
				}
			}
			const updatedIds = idsArray.join(',')
			setSelectedFileIds(updatedIds) // Exibe no console o estado atualizado
			return updatedIds
		})
	}

	const handleChangeModal = () => {
		setOpenInteractModal(false)
		setResponses([])
		setInputText('')
	}

	const onOpenInteractModal = (workspace: WorkSpace) => {
		setOpenInteractModal(true)
		setSelectedWorkSpace(workspace)
		getAllFilesInWorkSpace(workspace.files_ids)
		setResponses([])
	}

	const handleFileSelection = (fileId: string, isChecked: boolean) => {
		setFilesSelected(prev => {
			const updatedFiles = isChecked
				? [...prev, fileId] // Adiciona o ID se marcado
				: prev.filter(id => id !== fileId) // Remove o ID se desmarcado

			return updatedFiles
		})
	}

	useEffect(() => {
		getAllPrompts()
	}, [])

	return (
		<div className="bg-white p-4 flex flex-col gap-2">
			{openInteractModal && selectedWorkSpace ? (
				<Modal
					open={openInteractModal}
					title="Interact File"
					size="xl"
					onCancel={handleChangeModal}
					cancelLabel="Close">
					<div className="p-4 h-full flex bg-[#ededed]">
						<div className="p-4 bg-white gap-4 flex flex-col max-w-[40%] w-[40%] overflow-hidden">
							<div className="flex flex-col items-end justify-end">
								<TextStyleCaption>Creator:</TextStyleCaption>
								<div className="flex items-center gap-1 cursor-pointer hover:bg-gray-100">
									<span className="material-symbols-outlined">person</span>
									<TextStyleBodyDefaultLarge>@{selectedWorkSpace.owner_name}</TextStyleBodyDefaultLarge>
								</div>
							</div>
							<Divider />
							<div className="flex items-center gap-2 truncate">
								<span className="material-symbols-outlined">description</span>
								<TextStyleBodyDefault className="truncate">{selectedWorkSpace.content}</TextStyleBodyDefault>
							</div>
							<div className="max-h-[600px] overflow-y-auto">
								{inFetchWorkSpace ? (
									<Loading />
								) : workSpaceFiles && workSpaceFiles.length ? (
									workSpaceFiles.map((file: CommunityFile, key: number) => (
										<div key={key} className="flex items-center gap-2">
											<input
												id="default-checkbox"
												type="checkbox"
												checked={filesSelected && filesSelected.includes(file.gpt_file_id)}
												onChange={e => {
													handleFileSelection(file.gpt_file_id, e.target.checked)
												}}
												value={file.gpt_file_id}
												className="text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
											/>
											<TextStyleCaption className="text-sm font-medium text-gray-900">
												{file.document_name}
											</TextStyleCaption>
										</div>
									))
								) : (
									<TextStyleBodyDefaultLarge>No knowledge base for this space</TextStyleBodyDefaultLarge>
								)}
							</div>
						</div>
						<div className="w-full justify-end items-center flex flex-col">
							{responses && responses.length ? (
								<div className="overflow-y-auto p-2 w-full">
									{responses.map((response, index) => (
										<div key={index} className="flex flex-col w-full">
											{response.includes('Question:') ? (
												<div className="w-full flex items-end  justify-end ">
													<div className="w-fit p-2 bg-[#F9F9F9] rounded-[100px]">
														<TextStyleBodyDefaultMedium>
															{response.includes('Question:') ? response.split('Question: ')[1] : ''}
														</TextStyleBodyDefaultMedium>
													</div>
												</div>
											) : (
												''
											)}

											{response.includes('Answer:') ? (
												<div className="p-2 w-full items-start justify-start flex rounded-[10px]">
													<div className="flex w-full h-full items-start gap-2">
														<img
															src="https://alpha.kinera.network/static/media/kinera_.aa99fcdcf04e75b583bf.webp"
															className="w-[30px] rounded-full bg-white p-1"
														/>
														<TextStyleBodyDefaultMedium>
															{response.includes('Answer:') ? response.split('Answer: ')[1] : ''}
														</TextStyleBodyDefaultMedium>
													</div>
												</div>
											) : (
												''
											)}
										</div>
									))}
								</div>
							) : (
								''
							)}
							{loading ? <Loading /> : ''}
							<div className="bg-white p-4 w-[90%] rounded-[10px] mt-4">
								<div className="flex flex-col gap-1 items-end justify-end">
									<textarea
										maxLength={300}
										id="description"
										rows={4}
										value={inputText}
										onChange={e => setInputText(e.target.value)}
										className="block p-2.5 w-full text-sm text-gray-900 border-2 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-600"
										placeholder="Start typing"></textarea>
									<Button
										value="Send"
										size="small"
										onClick={handleSend}
										disabled={loading || inputText.trim() === ''}
									/>
								</div>
							</div>
						</div>
					</div>
				</Modal>
			) : (
				''
			)}
			<div className="flex items-center gap-2 w-full justify-end">
				<Button
					icon="add"
					value="Create workspace"
					size="small"
					onClick={() => {
						handleModalChange()
					}}
				/>
			</div>
			{showModal && (
				<Modal
					title="Create new Workspace"
					open={showModal}
					onClose={onClose}
					onConfirm={onConfirm}
					confirmDisabled={!title || !content || !type}
					confirmLabel={isEdit ? 'Save' : 'Create'}
					cancelLabel="Cancel">
					<div className="flex flex-col gap-1">
						<InputText
							type="text"
							value={title}
							label="Workspace name"
							maxLength={30}
							required
							onChange={(e: any) => {
								handleInputChange(e, 'title')
							}}
						/>

						<div className="flex flex-col  gap-1">
							<div className="flex items-center gap-1">
								<DsyLabel className="font-[14px] text-[14px]">Base model</DsyLabel>
								<DsyRequired>*</DsyRequired>
							</div>
							<div className="flex items-center gap-2">
								{models.map((model: { value: string; label: string }, key: number) => (
									<div className="flex items-center " key={key}>
										<input
											id="radio-image"
											type="radio"
											value="img"
											name="file-type"
											className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
											onChange={() => setType(model.value)}
											checked={type === model.value}
										/>
										<label htmlFor="radio-image" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
											{model.label}
										</label>
									</div>
								))}
							</div>
						</div>

						<div className="flex flex-col gap-2">
							<DsyLabel>
								Description <span className="text-red-300">*</span>
							</DsyLabel>
							<textarea
								onChange={e => {
									handleInputChange(e.target.value, 'content')
								}}
								value={content}
								maxLength={300}
								id="description"
								rows={4}
								className="block p-2.5 w-full text-sm text-gray-900 border-2  rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-600"
								placeholder="Write a description for this environment"></textarea>
						</div>

						<div className="flex flex-col gap-2">
							{inFetchFiles ? (
								<Loading></Loading>
							) : (
								<div className="max-w-h-[300px] overflow-y-auto gap-2 flex flex-col">
									<DsyLabel>Knowledge base</DsyLabel>
									{files && files.length ? (
										files.map((fie: CommunityFile, key: number) => (
											<div>
												<div className="flex items-center">
													<input
														id="default-checkbox"
														type="checkbox"
														onChange={e => handleCheckboxChange(fie.gpt_file_id, e.target.checked)}
														value={fie.gpt_file_id}
														className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
													/>
													<label htmlFor="default-checkbox" className="ms-2 text-sm font-medium text-gray-900">
														{fie.document_name}
													</label>
												</div>
											</div>
										))
									) : (
										<TextStyleBodyDefaultMedium>
											No to knowledge bases added in this community
										</TextStyleBodyDefaultMedium>
									)}
								</div>
							)}
						</div>
					</div>
				</Modal>
			)}
			{inFetch ? (
				<Loading />
			) : prompts && prompts.length ? (
				prompts.map((prompt: WorkSpace, key: number) => (
					<div key={key} className="flex w-full flex-col  gap-1 p-2 border border-1 rounded-[4px] cursor-pointer">
						<div className="w-full flex items-center  justify-between">
							<p className="text-[18px]">{prompt.name}</p>
							<div className="flex items-center gap-1">
								{user && user.id == prompt.owner_id ? (
									<Button
										size="small"
										type='subtle'
										icon='edit'
										value="Edit"
										onClick={() => {
											handleOpenEditModal(prompt)
										}}></Button>
								) : (
									''
								)}
								<Button
									size="small"
									value="Interact"
									onClick={() => {
										onOpenInteractModal(prompt)
									}}></Button>
							</div>
						</div>
						<div className="flex  w-full flex justify-between">
							<div className="flex flex-col max-w-[740px] break-words">
								<TextStyleCaption>Description</TextStyleCaption>
								<p>{prompt.content}</p>
							</div>
							<div>
								<TextStyleCaption>Creator</TextStyleCaption>
								<Link to={`social/view/${prompt.owner_name}`}>
									<TextStyleBodyDefaultLarge>@{prompt.owner_name}</TextStyleBodyDefaultLarge>
								</Link>
							</div>
						</div>
					</div>
				))
			) : (
				<TextStyleBodyDefaultMedium>This community is still without Workspace's</TextStyleBodyDefaultMedium>
			)}
		</div>
	)
}
