import React from 'react'

import KineContext from '../../context/KineContext'

import './style.scss'
import { Loading } from '../../components/loading'
import TableList from '../../components/tableList/TableList'
import { RankingService } from '../../service/ranking/index'
import { group1, group2, group3, group4 } from 'src/constants/rankings'
import Accordion from 'src/components/_Dsc/Accordion'
import RankingCards from '../../components/tableList/TableList'
import { TextStyleBodyDefault, TextStyleH1, TextStyleH3 } from 'src/components/_Dsc/typography'
import BreadCrumb from 'src/components/_Dsc/Breadcrumb'
import { CardContainer, CardInner } from 'src/components/_Dsc/Card'
const Rankings: React.FC = () => {
	const { apiState } = React.useContext(KineContext)
	const [ranking, setRanking] = React.useState<any | null>(null)
	const path = [
		{ label: 'Home', link: '/' },
		{ label: 'Rankings', link: '' },
	]
	const getAllData = async () => {
		let requestRankings = await RankingService.getRankingLists(apiState)
		setRanking(requestRankings)
	}

	React.useEffect(() => {
		if (apiState) {
			getAllData()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [apiState])

	return (
		<div className="w-full  mb-10 page-padding  flex flex-col">
			<BreadCrumb routes={path} />
			<CardInner>
				<CardContainer className="flex flex-col">
					<div className="flex flex-col ">
						<TextStyleH1>Rankings</TextStyleH1>
						<TextStyleBodyDefault>
							In <b className="ml-1">Rankings</b>, we use the KinexPlay token to classify the best-moving pictures and
							the best video content, creating a pool in SEE for future streaming channels.
						</TextStyleBodyDefault>
					</div>
					{ranking ? (
						<div className="w-full flex flex-col h-full gap-[8px] bg-white p-4 mt-4 rounded-[4px]">
							<Accordion
								title="Crypto Tube"
								description="The best reports on market trends, new projects, general news, blockchain technology and its fundamentals.">
								<RankingCards
									data={ranking}
									group={group1}
									title="Crypto Tube"
									remove="Crypto Tube"
									description="The best reports on market trends, new projects, general news, blockchain technology and its fundamentals."
								/>
							</Accordion>

							<Accordion
								title="Artificial Intelligence"
								description="Here, we list AI videos from entertainment to experimental art and advanced simulations.">
								<RankingCards
									data={ranking}
									group={group2}
									title="Artificial Intelligence"
									description="Here, we list AI videos from entertainment to experimental art and advanced simulations."
								/>
							</Accordion>

							<Accordion title="Cinema" description="Cinema rankings. Curation open to all.">
								<RankingCards
									data={ranking}
									group={group4}
									title="Cinema"
									remove="Cinema"
									description="Cinema rankings. Curation open to all."
								/>
							</Accordion>
						</div>
					) : (
						<Loading />
					)}
				</CardContainer>
			</CardInner>
		</div>
	)
}
export default Rankings
