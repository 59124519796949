import { Skeleton } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import Button from 'src/components/_Dsc/Button'
import { Loading } from 'src/components/loading'
import KineContext from 'src/context/KineContext'
import { Prompt } from './ImageToMovie'
import { createUserPrompt, getAllUsersPrompt } from 'src/service/profile/profileService'
import { TextStyleBodyDefault, TextStyleBodyDefaultLarge, TextStyleCaption } from 'src/components/_Dsc/typography'
import { formatDate } from 'src/constants/functions'

interface Props {
	communityId?: string
}
export const TextToMovie: React.FC<Props> = ({ communityId }) => {
	const { user } = React.useContext(KineContext)
	const [videoUrl, setVideoUrl] = useState<string | null>(null)
	const [inFetch, setInFetch] = useState<boolean>(false)
	const [inputText, setInputText] = useState('')
	const [inFetchPrompt, setInFetchPrompt] = useState<boolean>(false)
	const [prompts, setPrompts] = useState<Prompt[]>([])
	const [inFetchPrompts, setInFetchPrompts] = useState(false)

	const handleGenerateVideo = async (url: string) => {
		setInFetch(true)
		setVideoUrl('')

		let payload = {
			image: url,
			promptText: inputText,
		}

		try {
			const response = await axios.post('https://p2p-chat.kinera.network/generate-video', payload)
			await onCreatePrompt()
			if (response && response.data && response.data.videoUrl) {
				setVideoUrl(response.data.videoUrl)
				setInFetch(false)
				setInFetch(false)
			}
		} catch (error) {
			console.error('Error generating video:', error)
		}
	}

	const promptToImage = async () => {
		try {
			//setPrompt(inputText)
			setInputText('')
			setVideoUrl('')
			setInFetch(true)
			const response = await axios.post(
				'https://open-gpt.kinera.network/images/api/v1/generations',
				{
					prompt: inputText,
				},
				{
					headers: {
						Authorization: `Bearer ${process.env.REACT_APP_GPT_KEY}`,
						'Content-Type': 'application/json',
					},
				},
			)
			if (response && response.data && response.data[0] && response.data[0].url) {
				handleGenerateVideo(`https://open-gpt.kinera.network${response.data[0].url}`)
			}
		} catch (error) {
			console.error('Error:', error)
		}
	}

	const enchamntmentPrompt = async () => {
		try {
			setInputText('')
			setInFetchPrompt(true)
			const response = await axios.post(
				'https://open-gpt.kinera.network/api/chat/completions',
				{
					model: 'llama3.1:latest',
					messages: [
						{
							role: 'user',
							content: inputText
								? `enchantment prompt to generate video max char: 512 ${inputText}. Just give me the prompt without any additional response. `
								: 'enchantment prompt to generate video max char: 512. Just give me the prompt without any additional response. ',
						},
					],
					files: [],
				},
				{
					headers: {
						Authorization: `Bearer ${process.env.REACT_APP_GPT_KEY}`,
						'Content-Type': 'application/json',
					},
				},
			)
			setInputText(response.data.choices[0].message.content)
		} catch (error) {
			console.error('Error:', error)
		} finally {
			setInFetchPrompt(false)
		}
	}
	
	const onCreatePrompt = async () => {
		if (!communityId) {
			return
		}
		try {
			let req = await createUserPrompt(inputText, communityId, '3')
			if (req) {
				getAllUserPrompts()
			}
		} catch (error) {
			console.error('Error:', error)
		}
	}

	const getAllUserPrompts = async () => {
		if (!communityId) {
			return
		}
		try {
			let req = await getAllUsersPrompt('3')
			setPrompts(req)
		} catch (error) {
			console.error('Error:', error)
		}
	}
	useEffect(() => {
		getAllUserPrompts()
	}, [user])
	return (
		<div className="w-full h-full flex flex-col gap-2">
			{/**
             * {openModal ? (
            <PublishModal
                onConfirm={onPublish}
                open={openModal}
                file={selectedFile}
                setOpen={setOpenModal}
                setFileTitle={setCustomTitle}
            />
        ) : (
            ''
        )}
			
             */}
			<div className="flex items-start gap-2">
				<div className="min-w-[50%]">
					{videoUrl && (
						<div className="flex items-center justify-center p-2 w-full">
							<video controls className="mt-4 w-full max-w-md">
								<source src={videoUrl} type="video/mp4" />
								Your browser does not support the video tag.
							</video>
						</div>
					)}
					{inFetch && (
						<div className="flex items-center justify-center p-2 w-full">
							<Skeleton animation="wave" variant="rectangular" width={410} height={218} />
						</div>
					)}

					<div className="input-style flex flex-col   flex-col p-2 gap-2 bg-white rounded-[4px]">
						<div className="flex flex-col items-end w-full gap-2">
							<textarea
								maxLength={512}
								id="description"
								rows={4}
								value={inputText}
								onChange={e => setInputText(e.target.value)}
								className="block p-2.5 w-full text-sm text-gray-900 border-2 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-600"
								placeholder="Start typing"></textarea>
							<div className="flex items-center gap-2">
								{
									/**
									 * {inFetchPrompt ? (
									<Loading />
								) : (
									<Button
										value="Enchantment Prompt"
										icon="emoji_objects"
										size="small"
										onClick={enchamntmentPrompt}
										disabled={inFetch || inFetchPrompt}
									/>
								)}
									 */
								}
								<Button
									value="Generate"
									icon="animated_images"
									size="small"
									onClick={promptToImage}
									disabled={!inputText || inFetch || inFetchPrompt}
								/>
							</div>
						</div>
					</div>

					<div className="mt-2 bg-[#ececec] pl-2">
						<TextStyleBodyDefaultLarge className="text-sm mt-2 w-full items-center gap-1  flex  font-bold">
							<span className="material-symbols-outlined text-lg">settings</span>
							Engine
						</TextStyleBodyDefaultLarge>
						<div className="engine flex flex-col">
							<p className="flex  gap-2 title text-2xl ">
								<img src="https://framerusercontent.com/images/7Nhoxwn9eWYrqKjEewfXutR90U.png?scale-down-to=1024" width={'30px'} alt="Comfy Logo" />
								Comfy
							</p>
							
							<div className="flex items-center   gap-2">
								<p className=" text-sm">AnimateDiff
								</p>
								<span className="material-symbols-outlined">movie</span>
							</div>
							<div className="flex items-center   gap-2">
								<p className=" text-sm">CogVideoX
								</p>
								<span className="material-symbols-outlined">movie</span>
							</div>
						</div>
					</div>
				</div>
				<div className="min-w-[50%] max-h-[500px] overflow-y-auto gap-2 flex flex-col p-[5px]">
					{
						!inFetchPrompts
						? prompts && prompts.length > 0 ? (
							prompts.map((prompt: Prompt, key: number) => (
								<div key={key} className="p-2 bg-[#ececec] rounded-[4px]">
									<div className="w-full flex items-center justify-between">
										<TextStyleBodyDefault>@{prompt.owner_name}</TextStyleBodyDefault>
										<TextStyleCaption>{formatDate(prompt.created_at)}</TextStyleCaption>
									</div>
									<TextStyleCaption>{prompt.prompt_text}</TextStyleCaption>
								</div>
							))
						) : (
							''
						)
						: <Loading />
					}
				</div>
			</div>
		</div>
	)
}
