import React, { useEffect, useState } from 'react'
import Breadcrumb from 'src/components/_Dsc/Breadcrumb'
import { CardContainer, CardInner } from 'src/components/_Dsc/Card'
import {
	TextStyleBodyDefault,
	TextStyleBodyDefaultLarge,
	TextStyleBodyDefaultMedium,
	TextStyleCaption,
	TextStyleH1,
	TextStyleH3,
} from 'src/components/_Dsc/typography'
import { Community } from '../communities'
import KineContext from 'src/context/KineContext'
import { communityService } from 'src/service/community/communityService'
import { Loading } from 'src/components/loading'

import { useNavigate, useParams } from 'react-router-dom'
import Button from 'src/components/_Dsc/Button'
import Tabs from 'src/components/_Dsc/Tabs'
import TweetBox from '../social/components/createTweet/TweetBox'
import { getCommunityPosts, getCommunityPostsPublic, getCommunityUpdatePosts } from 'src/service/social/socialService'
import Feed from '../social/components/feed/Feed'
import { Skeleton } from '@mui/material'

import CommunityProposal from '../communityProposal'
import UserCommunityProposal from './components/Proposal'
import PendingApprovalModal from './components/PendingApprovalModal'
import { CommunitySharedDocuments } from './components/Storage/components/CommunitySharedDocuments'

import { CommunityStorage } from './components/Storage'
import { CommunityGovernance } from './components/Governance'
import Members from './components/Governance/Members'
import { ProdutionTools } from 'src/components/ProdutionsTools'
import { decodeHex } from 'src/constants/functions'

const path = [
	{ label: 'Home', link: '/' },
	{ label: 'Community', link: '' },
]

let categories = [
	{
		label: 'Social Timeline',
		value: 'Posts',
	},
	{
		label: 'Resource Center',
		value: 'Storage',
	},
	{
		label: 'Shared Texts',
		value: 'Community Documents',
	},

	{
		label: `Tools`,
		value: 'Tools',
	},

	{
		label: 'Members',
		value: 'Members',
	},

	/**
	 * {
		label: 'Proposals',
		value: 'Proposals',
	},
	 */
]

const options = [
	{ label: 'General', value: 'posts' },
	{ label: 'Updates', value: 'updates' },
]

type Params = {
	id: string
}

const interactions = [1, 2, 3, 4, 5]

const CommunityDetail: React.FC = () => {
	const { user, profile } = React.useContext(KineContext)
	const [isSelected, setIsSelected] = useState('Posts')
	const [isSelectedTimeline, setIsSelectedTimeline] = useState('posts')
	const [inFetch, setInFetch] = useState<boolean>(true)
	const [approvalModal, setApprovalModal] = useState(false)
	const [inFetchPosts, setInFetchPosts] = useState<boolean>(true)
	const [data, setData] = React.useState<any>([])
	const [updatePosts, setUpdatePosts] = React.useState<any>([])
	const [hasUserPermission, setHasUserPermission] = useState<boolean>(false)
	const [communityDetails, setCommunityDetails] = useState<Community>({} as Community)
	const [monthlyFee, setMonthlyFee] = useState<string | null>(null)
	const { id } = useParams()
	const navigate = useNavigate()

	const getCommunityDetails = async () => {
		setInFetch(true)
		let req = await communityService.getCommunitiesById(window.location.pathname.split('/')[2])
		const communityType = req?.communityType

		const fee = communityType?.Private?.monthlyFee || communityType?.Public?.monthlyFee
		setMonthlyFee(fee)
		let verifyUserCommunity = false
		setInFetchPosts(true)
		if (user && user.address) {
			let reqByUser = await communityService.getCommunitiesByUser(user.address)
			if (req && req.id && reqByUser && reqByUser.length) {
				reqByUser.forEach((val: Community) => {
					if (val.id === req.id) {
						verifyUserCommunity = true
						communityPosts()
						communityUpdatePosts()
						if (user && req.createdBy === user.address) {
							// Verifica se já existe um objeto com o mesmo valor no array
							const alreadyExists = categories.some(category => category.value === 'Governance')
							if (!alreadyExists) {
								categories.push({
									label: 'Governance',
									value: 'Governance',
								})
							}
						}
					}
				})
			} else {
				verifyUserCommunity = false
			}
		} else {
			await communityPublicPosts()
			verifyUserCommunity = false
		}

		setHasUserPermission(verifyUserCommunity)
		if(Object.keys(communityType)[0] === 'PublicDao') {
			setHasUserPermission(true)
		} 
		setCommunityDetails(req)
		setInFetch(false)
	}

	const handleTabsChange = (val: string) => {
		setIsSelectedTimeline(val)
	}

	const setFilterNotifications = (val: string) => {
		setIsSelected(val)
	}

	const communityPosts = async () => {
		let req = await getCommunityPosts(window.location.pathname.split('/')[2])
		setData(req)
		setInFetchPosts(false)
	}

	const communityPublicPosts = async () => {
		let req = await getCommunityPostsPublic(window.location.pathname.split('/')[2])
		//console.log("req", req)
		setData(req)
		setInFetchPosts(false)
	}

	const communityUpdatePosts = async () => {
		let req = await getCommunityUpdatePosts(window.location.pathname.split('/')[2])
		setUpdatePosts(req)
		setInFetchPosts(false)
	}

	useEffect(() => {
		getCommunityDetails()
	}, [user])

	useEffect(() => {
		if (user && user.address && isSelected === 'Posts') {
			setInFetchPosts(true)
			communityPosts()
			communityUpdatePosts()
		}
		
	}, [isSelectedTimeline, isSelected])

	return (
		<div className="page-padding">
			<PendingApprovalModal community={communityDetails} open={approvalModal} setOpen={setApprovalModal} />
			<Breadcrumb routes={path} />
			<CardInner>
				<CardContainer className="gap-2 flex flex-col">
					<div className="w-full flex flex-col">
						<div className="flex items-center justify-between">
							<TextStyleH1>
								{communityDetails && communityDetails.name ? decodeHex(communityDetails.name) : 'Community'}{' '}
							</TextStyleH1>
							
							{!inFetch &&
							hasUserPermission &&
							user &&
							communityDetails &&
							communityDetails.createdBy === user.address &&
							Object.keys(communityDetails.communityType)[0] === 'Private' &&
							monthlyFee &&
							parseInt(monthlyFee) <= 0 ? (
								<Button
									value="Pending approval"
									size="small"
									onClick={() => {
										setApprovalModal(true)
									}}
								/>
							) : (
								''
							)}
						</div>
						<TextStyleCaption>
								{decodeHex(communityDetails.description)}
							</TextStyleCaption>
						{!inFetch ? (
							<>
								{hasUserPermission ? (
									<Tabs onChange={setFilterNotifications} options={categories} value={isSelected}></Tabs>
								) : (
									''
								)}
							</>
						) : (
							''
						)}
					</div>

					{hasUserPermission ? (
						<div className="flex items-start gap-4">
							{isSelected === 'Posts' ? (
								<div className="options flex flex-col gap-2">
									{options.map((val: { label: string; value: string }, key: number) => (
										<div
											key={key}
											onClick={() => {
												handleTabsChange(val.value)
											}}
											className={`${
												val.value === isSelectedTimeline ? 'text-blue-600 bg-gray-200 rounded-[4px]' : ''
											} p-1 cursor-pointer hover:underline`}>
											<TextStyleBodyDefaultLarge>#{val.label}</TextStyleBodyDefaultLarge>
										</div>
									))}
								</div>
							) : (
								''
							)}
							<div className="flex flex-col gap-2 w-full rounded-[4px] ">
								{inFetch ? (
									<div className="p-4">
										<Loading />
									</div>
								) : isSelected === `Posts` ? (
									<div className="flex flex-col gap-2 ">
										{isSelectedTimeline === 'posts' ? (
											<div>
												{communityDetails && communityDetails.name ? (
													<div className="flex flex-col gap-2">
														<TweetBox
															getData={communityPosts}
															setData={setData}
															community={communityDetails}></TweetBox>
														<div className="">
															{!inFetchPosts ? (
																<Feed posts={data} profile={profile} setData={setData} />
															) : (
																interactions.map((value: any, index: number) => (
																	<div className="flex flex-col items-center p-4 border-y border-y-2" key={index}>
																		<div className="flex items-center justify-between mb-2 w-full">
																			<Skeleton variant="circular" width={60} height={60} />
																			<Skeleton variant="text" sx={{ fontSize: '1rem' }} width={'80%'} />
																		</div>
																		<Skeleton variant="rectangular" width={'100%'} height={100} />
																		<div className="mt-2 mb-2 w-full">
																			<Skeleton variant="text" sx={{ fontSize: '1rem' }} width={'30%'} />
																		</div>
																	</div>
																))
															)}
														</div>
													</div>
												) : (
													<p>No data to display</p>
												)}
											</div>
										) :<div>
										{communityDetails && communityDetails.name ? (
											<div className="flex flex-col gap-2">
												<TweetBox
													getData={communityPosts}
													setData={setData}
													community={communityDetails}></TweetBox>
												<div className="">
													{!inFetchPosts ? (
														<Feed posts={updatePosts} profile={profile} setData={setData} />
													) : (
														interactions.map((value: any, index: number) => (
															<div className="flex flex-col items-center p-4 border-y border-y-2" key={index}>
																<div className="flex items-center justify-between mb-2 w-full">
																	<Skeleton variant="circular" width={60} height={60} />
																	<Skeleton variant="text" sx={{ fontSize: '1rem' }} width={'80%'} />
																</div>
																<Skeleton variant="rectangular" width={'100%'} height={100} />
																<div className="mt-2 mb-2 w-full">
																	<Skeleton variant="text" sx={{ fontSize: '1rem' }} width={'30%'} />
																</div>
															</div>
														))
													)}
												</div>
											</div>
										) : (
											<p>No data to display</p>
										)}
									</div>}
									</div>
								) : isSelected === 'Governance' ? (
									<CommunityGovernance communityDetails={communityDetails} />
								) : isSelected === 'Community Documents' ? (
									id ? (
										<CommunitySharedDocuments community_id={id} />
									) : (
										''
									)
								) : isSelected === 'Proposals' ? (
									id ? (
										<UserCommunityProposal community_id={id} />
									) : (
										''
									)
								) : isSelected === 'Storage' ? (
									id ? (
										<CommunityStorage community_id={id} />
									) : (
										''
									)
								) : isSelected === 'Members' ? (
									<Members isGovernance={false} />
								) : isSelected === 'Tools' ? (
									<ProdutionTools isCommunity={true} community_id={id} />
								) : (
									''
								)}
							</div>
						</div>
					) : !inFetch ? (
						<>
							{hasUserPermission ? (
								''
							) : (
								<TextStyleBodyDefault>You do not have permission to access this content</TextStyleBodyDefault>
							)}
						</>
					) : (
						<Loading />
					)}
				</CardContainer>
			</CardInner>
		</div>
	)
}

export default CommunityDetail
