import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import LoggedUser from './loggedUser/LoggedUser'
import Logo from '../../assets/kinera_.webp'

import './style.scss'
import KineContext from '../../context/KineContext'

import DscIcons from '../../components/_Dsc/icons'

import Notifications from './notifications'

import { translate } from '../../context/Translate'
import ActionButton from '../../components/_Dsc/_button/Action'
import Button from 'src/components/_Dsc/Button'
import { Tooltip } from '@mui/material'

export default function Header(props: any) {
	const { setIsLogin, isLogin } = props
	const {
		user,
		setUser,
		setOnclicked,
		onClicked,
		showFullMenu,
		setShowFullMenu,
		profile,
		notifications,

		currentLangues,
	} = React.useContext(KineContext)
	const [showNotify, setShowNotify] = useState(false)
	let current = onClicked
	const location = useLocation()

	const handleChange = () => {
		setIsLogin(!isLogin)
		setOnclicked((current += 1))
	}

	const handleSetShowNotify = () => {
		setShowNotify(true)
	}

	const handleChangeMenuState = () => {
		const newMenuState = !showFullMenu
		setShowFullMenu(newMenuState)
	}

	const handleClickOutside = (event: any) => {
		if (event.target instanceof Node && !document.getElementById('notify-container')?.contains(event.target)) {
			setShowNotify(false)
		}
	}

	let currentNotificationsNumber = 0
	if (notifications) {
		notifications.forEach((notify: any) => {
			if (notify.status === 0) {
				currentNotificationsNumber += 1
			}
		})
	}

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside)

		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	const toolsRoute = location.pathname.includes('custom-tools') || location.pathname.includes('social')
	
	return (
		<header className={`header-shadow fixed z-[100]`}>
			<nav className="border-gray-200 px-4 header-text w-full">
				<div className="flex justify-between items-center ">
					<div className="flex items-center gap-2">
						{user && user.address && !toolsRoute &&(
							<Tooltip title={showFullMenu ? 'Hidden' : 'Open'}>
								<button
								className="cursor-pointer fixed-header"
								style={{left: showFullMenu ? '265px' : '65px' }}
								onClick={() => {
									handleChangeMenuState()
								}}>
								<DscIcons icon={showFullMenu ? 'menu_open' : 'arrow_menu_open'} size={'15px'} />
							</button>
							</Tooltip>
						)}

						<div className="flex items-center">
							<Link to="/" className="flex items-center">
								<img src={Logo} width="80" alt="logo" />
							</Link>
						</div>
					</div>
					<div className="flex items-center lg:order-2">
						<div className="relative flex items-center">
							{(!user || !user.name) && (
								<div className="flex items-center">
									<Button value="Login" onClick={handleChange} />
								</div>
							)}
							{user && user.name && (
								<div className="text-white flex gap-2 items-center  focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm mr-2">
									<div className="rounded-[4px] bg-gray-200 px-4 lg:px-5 py-2 lg:py-2.5">
										<LoggedUser setUser={setUser} user={user} />
									</div>
									<div
										className="cursor-pointer"
										onClick={() => {
											handleSetShowNotify()
										}}>
										<DscIcons
											icon={'notifications'}
											counter={notifications.length >= 1 && currentNotificationsNumber > 0}
											number={currentNotificationsNumber}
										/>
									</div>
									{profile && showNotify && (
										<div className="absolute  w-full top-[45px] -right-[5px] min-w-[430px] h-[700px] overflow-y-auto overflow-x-hidden">
											<div className="flex flex-col  rounded-[4px] w-full ">
												<div className="flex items-center gap-4 text-gray-800 ">
													<Notifications />
												</div>
											</div>
										</div>
									)}
								</div>
							)}
						</div>
					</div>

					<div className="hidden justify-between items-center w-full lg:flex lg:w-auto lg:order-1" id="mobile-menu-2">
						<div className="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
							<div
								className={`block py-2 pr-4 pl-3 text-black font-bold  hover:cursor-pointer   border-0  ${
									location.pathname === '/constellations' ? 'is-selected-option' : ''
								} `}>
								<Link to="/constellations">{translate('CONSTELATIONS', currentLangues.value)}</Link>
							</div>

							<div
								className={`block py-2 pr-4 pl-3 text-black font-bold  hover:cursor-pointer   border-0 ${
									location.pathname === '/rankings' ? 'is-selected-option' : ''
								} `}>
								<Link to="/rankings">{translate('RANKINGS', currentLangues.value)}</Link>
							</div>

							<div
								className={`block py-2 pr-4 pl-3 text-black font-bold  hover:cursor-pointer   border-0 ${
									location.pathname === '/social' ? 'is-selected-option' : ''
								}`}>
								<Link to="/social">Social Page</Link>
							</div>

							<div
								className={`block py-2 pr-4 pl-3 text-black font-bold  hover:cursor-pointer   border-0 ${
									location.pathname === '/communities'
										? 'is-selected-option'
										: ''
								}`}>
								<Link to="/communities">Communities</Link>
							</div>

							<div className="block py-2 pr-4 pl-3 text-black font-bold  hover:cursor-pointer   border-0 ">
								<Link to="/custom-tools">{translate('PRODUCT_TOOLS', currentLangues.value)}</Link>
							</div>

							{
								/**
								 * <div
								className={`block py-2 pr-4 pl-3 text-black font-bold  hover:cursor-pointer   border-0 ${
									location.pathname === '/winners-list' ? 'is-selected-option' : ''
								}`}>
								<Link to="/winners-list">See</Link>
							</div>
								 */
							}
						</div>
					</div>
				</div>
			</nav>
		</header>
	)
}
