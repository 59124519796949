import React, { useEffect, useState } from 'react'
import Button from 'src/components/_Dsc/Button'
import { Loading } from 'src/components/loading'
import KineContext from 'src/context/KineContext'
import ViewFile from './viewFile'

import { getAllUserFilesPrivate, getAllUserFilesPublic, removeUserFile } from 'src/service/storage/userImages'
import Tabs from 'src/components/_Dsc/Tabs'
import { getUserFiles, removeUserFile as removeIpfsFile } from 'src/service/storage/Ipfs'
import { CommunityFile } from 'src/pages/community/components/Actions/components/GPT/components/Rags'
import { TextStyleCaption } from 'src/components/_Dsc/typography'

interface Props {
	optionUserAddress?: string
}

const options = [
	{
		label: 'Ipfs storage files',
		value: 'ipfs',
	},
	{
		label: 'Private files',
		value: 'private',
	},
	{
		label: 'Public files',
		value: 'public',
	},
]

const MyPhotos: React.FC<Props> = ({ optionUserAddress }) => {
	const { user } = React.useContext(KineContext)
	const [images, setImages] = useState([] as any)
	const [isFetch, setIfetch] = useState(true)
	const [modal, setModal] = useState(false)
	const [selectedImage, setSelectedImage] = useState({} as any)
	const [isSelected, setIsSelected] = useState('ipfs')
	const [ipfsFiles, setIpfsFiles] = useState<CommunityFile[]>([])
	const serverAddress = 'https://ia-tool.kinera.network'

	const setFilterNotifications = (val: string) => {
		setImages([])
		val === 'private' ? getAllPrivateFiles() : getAllPublicFiles()
		setIsSelected(val)
	}

	const getAllUserIpfsFiles = async () => {
		let req = await getUserFiles(user.address)
		if (req && req.data) {
			console.log("req",req)
			setIpfsFiles(req.data)
		}
	}

	const closeModal = () => {
		setModal(false)
	}

	const handleShowModal = (image: any) => {
		setModal(true)
		setSelectedImage(image)
	}

	const getAllPrivateFiles = async () => {
		setIfetch(true)
		let req = await getAllUserFilesPrivate(user.address)
		if (req && req.length) {
			setImages(req)
		}
		setIfetch(false)
	}

	const getAllPublicFiles = async () => {
		setIfetch(true)
		let req = await getAllUserFilesPublic(user.address)
		if (req && req.length) {
			setImages(req)
		}
		setIfetch(false)
	}

	const openImage = (image_url: string) => {
		window.open(image_url, '_blank')
	}

	const onDeleteImage = async (file: CommunityFile) => {
		setIfetch(true)
		await removeIpfsFile(user.address, file.file_hash)
		getAllUserIpfsFiles()
		setIfetch(false)
	}

	useEffect(() => {
		setImages([])
		if (user) {
			isSelected === 'private' ? getAllPrivateFiles() : getAllPublicFiles()
			getAllUserIpfsFiles()
		}
	}, [user, modal])

	return (
		<div className="w-full h-full  flex flex-col ">
			{modal && <ViewFile selectedImage={selectedImage} onClose={closeModal}></ViewFile>}
			{isFetch ? (
				<Loading />
			) : (
				<div>
					<div className="mb-2 w-fit mx-auto">
						<Tabs onChange={setFilterNotifications} options={options} value={isSelected}></Tabs>
					</div>
					{/**
						 * {images && images.length ? (
						<div className="flex items-center gap-2 wrap flex-wrap">
							{images.map((val: any, key: number) => (
								<div
									key={key}
									className="relative grow basis-1/3 min-w-[calc(33.333%-1rem)] flex items-center justify-center group">
									<img src={val.url} className="image-card grow basis-1/3 w-full" />
									<div className="absolute post-image opacity-0 group-hover:opacity-100 transition-opacity duration-200">
										<Button
											value="View"
											onClick={() => {
												handleShowModal(val)
											}}
										/>
									</div>
								</div>
							))}
						</div>
					) : (
						'No IA image to display'
					)}
						 */}

					{isSelected === 'ipfs' ? (
						ipfsFiles && ipfsFiles.length ? (
							<div className="grid p-2 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
								{ipfsFiles.map((file: CommunityFile, key: number) => file.type !== 'community_file' && file.type !== 'rag' && (
									<div
										key={key}
										className="relative grow basis-1/3 min-w-[calc(33.333%-1rem)] flex items-center justify-center group">
										<div className='flex flex-col gap-1'>
											
										<img src={file.url} className="image-card grow basis-1/3 w-full" />
										<TextStyleCaption>{file.custom_name ?? file.file_name}</TextStyleCaption>
											</div>
										<div className="absolute post-image opacity-0 group-hover:opacity-100 transition-opacity duration-200">
											<div className="flex gap-2 items-center">
												<Button
													icon="delete"
													value="Delete"
													type="critical"
													size="small"
													onClick={() => {
														onDeleteImage(file)
													}}
												/>
												<Button
													icon="visibility"
													size="small"
													value="View"
													onClick={() => {
														openImage(file.url)
													}}
												/>
											</div>
										</div>
									</div>
								))}
							</div>
						) : (
							'No ipfs image to display'
						)
					) : (
						''
					)}
				</div>
			)}
		</div>
	)
}

export default MyPhotos
