import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import InitialSimbol from 'src/components/_Dsc/InitialSimbol'
import { TextStyleBodyDefault, TextStyleBodyDefaultMedium } from 'src/components/_Dsc/typography'
import { Loading } from 'src/components/loading'
import { communityService } from 'src/service/community/communityService'
import { CreateDocument } from './CreateDocument'
import { SuccessToast } from 'src/components/Toast'
import { ErrorToast } from 'src/components/Toast/Error'
import Button from 'src/components/_Dsc/Button'

interface Props {
	community_id: string
}

interface FileCommunity {
	id: string
	user_id: string
	html: string
	title: string
	owner_name: string
	description: string
	privacity: string
	is_community: string
	community_id: string
	created_at: string
	updated_at: string
}

export const CommunitySharedDocuments: React.FC<Props> = ({ community_id }) => {
	const navigate = useNavigate()
	const [data, setData] = useState([])
	const [inFetch, setInFetch] = useState<boolean>(true)
	const [successBuy, setSuccessBuy] = useState(false)
	const [isError, setIsError] = useState(false)
	const [showModal, setShowModal] = useState<boolean>(false)

	const getData = async () => {
		setInFetch(true)
		let req = await communityService.getCommunityDocuments(community_id)
		if (req && req.data) {
			setData(req.data)
		}
		setInFetch(false)
	}

	const redirectToDocument = (id: string) => {
		window.open(`https://shared-doc.kinera.network/document/${id}`)
	}

	const onOpen = () => {
		setShowModal(true)
	}

	React.useEffect(() => {
		getData()
	}, [])

	return (
		<div className="bg-white p-4 flex flex-col gap-2">
			<div className="flex items-center gap-2 w-full justify-end">
				<Button icon='add' value="Projects" size="small" onClick={onOpen} />
				
			</div>
			{successBuy ? <SuccessToast message="Document Create Success" /> : ''}
			{isError ? <ErrorToast message="Transaction error, review the information and try again" /> : ''}
			{showModal ? (
				<CreateDocument
					setSuccessBuy={setSuccessBuy}
					setIsError={setIsError}
					showModal={showModal}
					setShowModal={setShowModal}
					community_id={community_id}
					getData={getData}
				/>
			) : (
				''
			)}
			{inFetch ? (
				<Loading />
			) : data && data.length ? (
				data.map((val: FileCommunity, key: number) => (
					<div
						key={key}
						onClick={() => {
							redirectToDocument(val.id)
						}}
						className="hover:bg-gray-200  cursor-pointer bg-white rounded-[4px] border border-[#ededed] p-2 flex items-center justify-between gap-2">
						<TextStyleBodyDefault>Document: {val.title}</TextStyleBodyDefault>

						<div className="flex items-center gap-2">
							<TextStyleBodyDefault>{val.owner_name}</TextStyleBodyDefault>
							<InitialSimbol profile={{ img: undefined, name: val.owner_name }} />
						</div>
					</div>
				))
			) : (
				<TextStyleBodyDefaultMedium>There are no files in this community at the moment</TextStyleBodyDefaultMedium>
			)}
		</div>
	)
}
