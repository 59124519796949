import styled, { css } from 'styled-components'

import { cssTextStyleBodyDefault } from '../typography'
import { cssScrollbar, cssUnclickable, cssUnselectable } from '../Classes'

interface IDsyContainer {
	disabled: boolean
}

export const DsyContainer = styled.div<IDsyContainer>`
	display: flex;
	position: relative;
	width: 100%;

	${cssUnselectable}

	${({ disabled }) => disabled && cssUnclickable}
`

interface IDsyButton {
	color: 'subtle' | 'primary' | 'secondary' | 'critical' | 'warning'
	size: 'small' | 'medium'
	disabled: boolean
}

const cssButtonSmall = css`
	font-size: 14px;
	line-height: 16px;
	font-weight: 500;
	padding: 6px 10px;
`

const cssButtonMedium = css`
	font-size: 16px;
	line-height: 16px;
	font-weight: 500;
	padding: 10px 14px;
`

const cssButtonSublte = css`
	background-color: transparent;
	color: var(--text-default);

	&:hover {
		background-color: var(--background-default);
	}

	&:focus {
		border: 2px solid var(--border-primary);
	}
`
const cssButtonDisabledSubtle = css`
	color: var(--text-disable);
`

const cssButtonPrimary = css`
	background-color: var(--action-primary);
	color: var(--text-on);

	&:hover {
		background-color: var(--action-primary-dark);
	}
`

const cssButtonDisabledPrimary = css`
	background-color: var(--action-primary-light);
`

const cssButtonSecondary = css`
	background-color: var(--action-secondary);
	color: var(--text-on);

	&:hover {
		background-color: var(--action-secondary-dark);
	}
`

const cssButtonDisabledSecondary = css`
	background-color: var(--action-secondary-light);
`

const cssButtonCritical = css`
	background-color: var(--action-critical);
	color: var(--text-on);

	&:hover {
		background-color: var(--action-critical-dark);
	}
`

const cssButtonDisabledCritical = css`
	background-color: var(--action-critical-light);
`

const cssButtonWarning = css`
	background-color: var(--action-warning);
	color: var(--text-default);

	&:hover {
		background-color: var(--action-warning-dark);
	}
`

const cssButtonDisabledWarning = css`
	opacity: 0.5;
`

export const DsyButton = styled.button<IDsyButton>`
	width: 100%;
	border: none;
	font-family: var(--font-circular-std);
	border-radius: var(--border-radius);
	border: 2px solid transparent;
	display: flex;
	align-items: center;
	gap: 11px;

	${({ size }) => (size === 'medium' ? cssButtonMedium : cssButtonSmall)}

	${({ color, disabled }) =>
		color === 'primary' && disabled
			? cssButtonDisabledPrimary
			: color === 'primary' && !disabled
			? cssButtonPrimary
			: color === 'secondary' && disabled
			? cssButtonDisabledSecondary
			: color === 'secondary' && !disabled
			? cssButtonSecondary
			: color === 'critical' && disabled
			? cssButtonDisabledCritical
			: color === 'critical' && !disabled
			? cssButtonCritical
			: color === 'warning' && disabled
			? cssButtonDisabledWarning
			: color === 'warning' && !disabled
			? cssButtonWarning
			: color === 'subtle' && disabled
			? cssButtonDisabledSubtle
			: cssButtonSublte}
`

export const DsyButtonLabel = styled.span`
	min-width: 20px;
`

export const DsyButtonIcon = styled.i`
	font-size: 16px;
`

export const DsyInput = styled.input`
	${cssTextStyleBodyDefault};
	border-radius: var(--border-radius) !important;
	border: 2px solid var(--border-default) !important;
	width: 100% !important;
	height: auto !important;
	line-height: 20px !important;
	padding: 8px 40px 8px 12px !important;
	transition: var(--transition-fast);
`

interface IDsyDropdownSelect {
	position: 'left' | 'right'
}

export const DsyDropdownSelect = styled.div<IDsyDropdownSelect>`
	box-shadow: 0px 0px 1px rgba(9, 30, 66, 0.32), 0px 8px 12px rgba(9, 30, 66, 0.15);
	background-color: var(--surface-default);
	position: absolute;
	border-radius: var(--border-radius);
	top: calc(100% + 8px);
	z-index: var(--z-index-dropdown);
	padding: 8px 0px;
	display: block;
	width: max-content;
	${({ position }) => (position === 'right' ? 'left: 0;' : 'right:0;')}
`

export const DsyDropdownList = styled.div`
	${cssScrollbar}

	max-height: calc(40px * 8);
	overflow-y: visible;
`

interface IDsyDropdownItem {
	selected: boolean
}

export const DsyDropdownItem = styled.div<IDsyDropdownItem>`
	white-space: wrap;
	word-wrap: break-word;
	background-color: ${({ selected }) => (selected ? 'var(--icon-disable)' : 'inherit')};

	&:hover {
		background-color: var(--surface-neutral);
	}
`

export const DsyDropdownItemCheckbox = styled.div`
	padding: 4px 20px;
`

export const DsyDropdownItemLabel = styled.div`
	${cssTextStyleBodyDefault}
	flex-direction: column;
	width: 100%;
	line-height: 24px !important;
	margin: 0;
	display: flex;
	
	gap: 2px;
	padding: 8px 20px;
	cursor: pointer;
`

interface IDsySearchContainer {
	size: 'small' | 'medium'
}

export const DsySearchContainer = styled.div<IDsySearchContainer>`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;

	border-radius: var(--border-radius) !important;
	background-color: var(--surface-default) !important;
	border: 2px solid var(--border-primary) !important;
	width: 100% !important;
	height: auto !important;
	line-height: 20px !important;
	padding: ${({ size }) => (size === 'medium' ? '8px 8px 8px 12px' : '4px 8px 4px 12px')} !important;
	transition: var(--transition-fast);
`

export const DsySearchInput = styled.input`
	${cssTextStyleBodyDefault}

	width: 100%;
	border: none !important;
	line-height: 20px !important;
	color: var(--text-default) !important;
	background: transparent !important;
	transition: none !important;
`

export const DsySearchClear = styled.i`
	font-size: 14px;
	color: var(--icon-neutral);
	left: auto !important;
	right: 12px;
	pointer-events: all;
	cursor: pointer;
`
