import React from 'react'
import KineraLogo from '../../assets/Ativo_39bg_newls.webp'
import './styles.scss'
import {
	TextStyleCaption,
	TextStyleCaptionHighlight,
	TextStyleH1,
	TextStyleH2,
	TextStyleH3,
} from 'src/components/_Dsc/typography'
import Button from 'src/components/_Dsc/Button'
import DscIcons from 'src/components/_Dsc/icons'
import { Link, useNavigate } from 'react-router-dom'
import { CardContainer, CardInner } from 'src/components/_Dsc/Card'
const CustomTools: React.FC = () => {
	const navigate = useNavigate()

	const redirectToLink = (link: string) => {
		window.open(link)
	}

	return (
		<div className="w-full h-full flex">
			<div className="left-content w-[30%] flex flex-col justify-between">
				<div>
					<img src={KineraLogo} className="logo" />
					<div className="text">
						<TextStyleH2 className="text-white">
							Kinera provides content creation tools to simplify the process for its users.
						</TextStyleH2>
					</div>
					<div
						className="mt-4 flex items-center gap-1 hover:opacity-70"
						onClick={() => {
							navigate('/')
						}}>
						<DscIcons icon="arrow_back" />
						<Button value="Return to Kinera network" type="subtle"></Button>
					</div>
				</div>
				<div className="mb-[20px] flex items-center gap-[30px]">
					<TextStyleCaption>Powered by Invisible Hand Team Lab</TextStyleCaption>|
					<TextStyleCaption className="hover: opacity-70">
						<Link to="https://www.kinera.network" target="_blank">
							About Kinera Network
						</Link>
					</TextStyleCaption>
				</div>
			</div>
			<div className="right-content w-[60%] flex flex-col ml-20 mt-[30px]">
				<TextStyleH3>Tools</TextStyleH3>
				<div className="line-divider"></div>
				<div className="flex flex-col gap-4">
					<CardInner className="bg-white">
						<CardContainer>
							<TextStyleH2 className="flex items-center gap-2">
								<DscIcons icon="groups"></DscIcons> Collaborative Text
							</TextStyleH2>
							<TextStyleCaption>
								Create texts shared with your social network (followers and communities)
							</TextStyleCaption>
							<div className="mt-4 w-full gap-4 flex items-end justify-end">
								<Button
									value="View"
									size="small"
									onClick={() => {
										redirectToLink('https://shared-doc.kinera.network/')
									}}></Button>
							</div>
						</CardContainer>
					</CardInner>
				{
					/**-
					 * 	<CardInner className="bg-white">
						<CardContainer>
							<TextStyleH2 className="flex items-center gap-2">
								<DscIcons icon="smart_toy"></DscIcons> GPT
							</TextStyleH2>
							<TextStyleCaption>Create interactions with different language models.</TextStyleCaption>
							<div className="mt-4 w-full gap-4 flex items-end justify-end">
								<Button
									value="View"
									size="small"
									onClick={() => {
										redirectToLink('https://gpt-tool.kinera.network/')
									}}></Button>
							</div>
						</CardContainer>
					</CardInner>
					 */
				}
					<CardInner className="bg-white">
						<CardContainer>
							<TextStyleH2 className="flex items-center gap-2">
								<DscIcons icon="adaptive_audio_mic"></DscIcons> P2P Video calls
							</TextStyleH2>
							<TextStyleCaption>Make a P2P private video call and record it on your computer.</TextStyleCaption>
							<div className="mt-4 w-full gap-4 flex items-end justify-end">
								<Button
									value="View"
									size="small"
									onClick={() => {
										redirectToLink('https://meet.kinera.network/')
									}}></Button>
							</div>
						</CardContainer>
					</CardInner>
					
						<CardInner className="bg-white">
						<CardContainer>
							<TextStyleH2 className="flex items-center gap-2">
								<DscIcons icon="movie"></DscIcons> Image or Video Generate
							</TextStyleH2>
							<TextStyleCaption>
								The Kinera Image and video Generator feature allows users to create images or videos from text prompts.
								This tool is ideal for marketers, educators, and content creators who want to bring their concepts to
								life through video, making the process of video creation both efficient and creative.
							</TextStyleCaption>
							<div className="mt-4 w-full gap-4 flex items-end justify-end">
								<Button
									value="Omegatron Any to Any"
									size="small"
									onClick={() => {
										navigate('/anytoany')
									}}></Button>
								<Button
									value="Prompt"
									size="small"
									onClick={() => {
										redirectToLink('https://tools.kinera.network/image')
									}}></Button>
							</div>
						</CardContainer>
					</CardInner> 

					{
						/**
						 * <CardInner className="bg-white">
						<CardContainer>
							<TextStyleH2 className="flex items-center gap-2">
								<DscIcons icon="abc"></DscIcons> Dataset Search
							</TextStyleH2>
							<TextStyleCaption>
								The Omegatron Dataset Search feature allows users to search for videos, text prompts, images, or audio
								inputs to build their Data Sets. This tool is perfect for researchers, content creators, and educators
								seeking to efficiently locate specific video content, making finding the right video content seamless
								and practical
							</TextStyleCaption>
							<div className="mt-4 w-full flex items-end justify-end">
								<Button
									onClick={() => {
										redirectToLink('https://dataset-search.kinera.network/')
									}}
									value="View"
									size="small"></Button>
							</div>
						</CardContainer>
					</CardInner>
						 */
					}
				</div>
			</div>
		</div>
	)
}

export default CustomTools
