import React, { useState } from 'react'
import KineContext from './KineContext'
import { User } from '../interface'
import { ModerationService } from '../service/moderation/moderationService'
import { api } from '../service/substrate/substrate'
import { getSocialProfileByAddres } from '../service/social/socialService'
import { NotificationService } from '../service/notification/notificationService'
import { web3Accounts, web3Enable } from '@polkadot/extension-dapp';
import Cookies from 'js-cookie'
import API from 'src/service/api'
const defaultColors = {
	action: '#d090dc',
	info: '#d090dc',
	text: '#292929',
	alert: '#f1f664ed',
	alertText: '#000',
	blackColor: '#0000',
	sidenav: '#8f559a',
	bg: '#e6b8f0',
	asideText: '#fff',
	title: '#292929',
	logo: '#fff',
}

interface INotification {
	content: string
	id: number
	status: number
	type: string
	user_id: number
}

const optionsLanguage = [
	{ label: 'English', value: 'en' },
	{ label: 'Espanhol', value: 'es' },
	{ label: 'French', value: 'fr' },
]

export const KineProvider = ({ children }: any) => {
	const [user, setUser] = useState({} as any)

	const [globalToast, setGlobalToast] = useState<boolean>(false)
	const [onClicked, setOnclicked] = useState<any>(0)
	const [apiState, setApiState] = useState<any>(null)
	const [isModerator, setIsModerator] = useState(false)
	const [profile, setGlobalProfile] = useState<any>(null)
  	const [verifyCurrentUserStorage, setCurrentUserStorage] = useState<boolean>(false)
	const [globalSnack, setGlobalSnack] = useState(false)
	const [openHelp, setOpenHelp] = useState(false)
	const [currentTab, setCurrentTab] = useState('')
	const [showFullMenu, setShowFullMenu] = useState(true)
	const [notifications, setNotifications] = useState<INotification[]>([])
	const [currentLangues, setCurrentLanguage] = useState(optionsLanguage[0])
	const [storageUsed, setStorageUsed] = React.useState<string>('')
	const [onQueue, setOnQueue] = React.useState(false)
	const [globalMessage, setGlobalMessage] = useState('Waiting for transaction to complete.')

	let userData = Cookies.get('kinera-cookie')

	const handleSetGlobalFetch = () => {
		getUserProfile()
	}

	const handleGlobalMessage = (param:string) :void => {
		setGlobalMessage(param)
		let userData = Cookies.get('kinera-cookie')
		if (userData && param.includes('error')) {
			//Cookies.remove('kinera-cookie')
		}
	}

	const getRecoveryUser = async () => {
		if (userData) {
			await getUserProfile()
		} 
	}

	const verifyUserIsModerator = async () => {
		let req: any = await ModerationService.verifyModerator(user, apiState)
		if (req && req.rank) {
			setIsModerator(true)
		} else {
			setIsModerator(false)
		}
	}

	const getInitialColor = () => {
		/**const savedColor = localStorage.getItem('kinera__currentColor') */
		return defaultColors
	}

	const [color, setColor] = useState(getInitialColor())

	const connect = async () => {
		let con = await api()
		setApiState(con)
	}

	const getUserProfile = async () => {
		setGlobalToast(true)
		if (userData) {
			const parsedUser = JSON.parse(userData)
			let myProfile = await getSocialProfileByAddres(parsedUser.address)

			if (myProfile) {
				setGlobalProfile(myProfile)

				setUser(myProfile)
				setGlobalToast(false)
			} else {
				setGlobalToast(false)
			}
		}
	}

	const getAllNotifyUser = async () => {
		if (profile && profile.id) {
			let req = await NotificationService.getAllNotify(profile.id)
			if (req && req.data) setNotifications(req.data)
		}
	}

	const swapCurrentHelp = (value: boolean) => {
		setOpenHelp(value)

	}

	const renewUserToken = async () => {
		const userCookie = Cookies.get('kinera-cookie');
	  
		if (userCookie) {
		  try {
			const parsedUser = JSON.parse(userCookie);
	  
			if (parsedUser && parsedUser.address && parsedUser.password) {
			  try {
				// Faz a solicitação para renovar o token
				const response = await API.post('/auth/renew-token', {
				  address: parsedUser.address,
				  password: parsedUser.password,
				});
	  
				if (response && response.data && response.data.access_token) {
				  // Atualiza o token no cookie
				  const updatedUser = {
					...parsedUser,
					access_token: response.data.access_token,
				  };
				  Cookies.set('kinera-cookie', JSON.stringify(updatedUser), {
					expires: 7, // Cookie válido por 7 dias
				  });
	  
				  // Atualiza o estado global do usuário
				  setUser(updatedUser);
	  
				  console.log('Token renovado com sucesso.');
				}
			  } catch (error) {
				console.error('Erro ao renovar o token:', error);
				// Remove o cookie se a renovação falhar
				Cookies.remove('kinera-cookie');
			  }
			}
		  } catch (error) {
			console.error('Erro ao processar kinera-cookie:', error);
			Cookies.remove('kinera-cookie');
		  }
		}
	};

	const waitForInjectedAccount = async (interval = 1000): Promise<any> => {
		return new Promise((resolve, reject) => {
			const checkAccounts = async () => {
				const extensions = await web3Enable('kinera')
				if (extensions.length > 0) {
					console.log('Found injected accounts:', extensions);
					resolve(extensions);
				} else {
					console.log('No injected accounts found. Retrying...');
					setTimeout(checkAccounts, interval);
				}
			};
			checkAccounts();
		});
	};

	React.useEffect(() => {
		const checkInjectedAccounts = async () => {
			try {
				const accounts = await waitForInjectedAccount();
				if (accounts.length > 0) {
					console.log('Injected accounts available:', accounts);
				}
			} catch (error) {
				console.error('Error checking injected accounts:', error);
			}
		};
	
		checkInjectedAccounts();
	}, []);

	  React.useEffect(() => {
		if (!apiState) {
			connect()
		}
		getRecoveryUser()
		renewUserToken();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userData, storageUsed])

	
	return (
		<KineContext.Provider
			value={{
				user,
				setUser,
				handleSetGlobalFetch,
				setIsModerator,
				isModerator,
				color,
				setColor,
				apiState,
				onClicked,
				setOnclicked,
				globalToast,
				setGlobalToast,
				profile,
				setGlobalProfile,
				globalSnack,
				setGlobalSnack,
				openHelp,
				swapCurrentHelp,
				showFullMenu,
				setShowFullMenu,
				notifications,
				setNotifications,
				getAllNotifyUser,
				optionsLanguage,
				currentLangues,
				setCurrentLanguage,
				currentTab,
				verifyCurrentUserStorage,
				setCurrentUserStorage,
				storageUsed,
				setStorageUsed,
				onQueue,
				setOnQueue,
				globalMessage,
				handleGlobalMessage
			}}>
			{children}
		</KineContext.Provider>
	)
}
