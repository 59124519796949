import React, { useEffect, useState } from 'react'
import KineContext from 'src/context/KineContext'
import { getAllUserChats } from 'src/service/messenger'
import { path } from './constants'
import Breadcrumb from 'src/components/_Dsc/Breadcrumb'
import { CardContainer, CardInner } from 'src/components/_Dsc/Card'
import { Loading } from 'src/components/loading'
import { TextStyleBodyDefaultMedium, TextStyleCaption, TextStyleH1 } from 'src/components/_Dsc/typography'
import InitialSimbol from 'src/components/_Dsc/InitialSimbol'
import Button from 'src/components/_Dsc/Button'
import { Divider } from '@mui/material'
import { useNavigate } from 'react-router-dom'

export interface Chat {
	id: number
	owner_id: number
	owner_name: string
	peer_id: number
	peer_name: string
	name: string
	created_at: string
	updated_at: string
}
export const SocialMenseger: React.FC = () => {
	const navigate = useNavigate()
	const { user } = React.useContext(KineContext)
	const [inFetch, setInFetch] = useState<boolean>(false)
	const [chats, setChats] = useState<Chat[]>([])

	const getCurrentUserChats = async () => {
		setInFetch(true)
		let req = await getAllUserChats(user.id)
		setChats(req)
		setInFetch(false)
	}

	const formatDate = (dateString: string) => {
		const date = new Date(dateString)
		const day = String(date.getDate()).padStart(2, '0')
		const month = String(date.getMonth() + 1).padStart(2, '0') // Months are 0-indexed
		const year = date.getFullYear()
		const time = date.toTimeString().split(' ')[0] // Get the time portion only (HH:MM:SS)

		return `${day}/${month}/${year} - ${time}`
	}

	const redirectToChat = (chatId: number) => {
		navigate(`/chat/${chatId}`)
	}

	useEffect(() => {
		if (user) {
			getCurrentUserChats()
		}
		if(!user || !user.address) {
			navigate(`/`)
		}
	}, [user])

	return (
		<div className="page-padding">
			<Breadcrumb routes={path} />
			<CardInner>
				
				<CardContainer className="gap-2 flex flex-col">
				<TextStyleH1>Social Chats</TextStyleH1>
					{inFetch ? (
						<Loading />
					) : (
						<div className='flex flex-col gap-2'>
							{chats && chats.length ? (
								chats.map((chat, key: number) => (
									<div onClick={() => {redirectToChat(chat.id)}} key={key} 
									className="flex items-center gap-4 justify-between bg-[#ffff] p-2 rounded-[4px] cursor-pointer hover:opacity-70">
										<div className="flex items-center gap-4">
											<InitialSimbol
												profile={{
													img: undefined,
													name: chat.peer_name === user.name ? chat.owner_name : chat.peer_name,
												}}
											/>

											<div className="flex flex-col gap-1">
												<TextStyleBodyDefaultMedium>{chat.peer_name === user.name ? chat.owner_name : chat.peer_name}</TextStyleBodyDefaultMedium>
												<TextStyleCaption>{formatDate(chat.created_at)}</TextStyleCaption>
											</div>
										</div>
										<Button value="View" icon="visibility" size='small' type='subtle' onClick={() => {redirectToChat(chat.id)}}/>
									</div>
								))
							) : (
								<TextStyleCaption>No conversations available at the moment</TextStyleCaption>
							)}
							<Divider />
						</div>
					)}
				</CardContainer>
			</CardInner>
		</div>
	)
}
